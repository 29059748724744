<template>
  <el-row :gutter="20">
    <template v-for="(item,index) in routes">
      <el-col v-if="item.navigate"
              class="Footer-item-col"
              :key="index"
              :span="6">
        <el-radio class="Footer-item-col-div"
                  v-on:click.native.prevent="$route.path != item.path?$router.push(item.path):''"
                  v-model="pitch_on"
                  :label="item.path"
                  border>
          <el-image style="width: 21pt; height: 21pt;"
                    :src="$route.path != item.path?item.meta.icon:item.meta.icon2"
                    fit="cover"></el-image>
          <div>{{item.meta.title}}</div>
        </el-radio>
      </el-col>
    </template>
  </el-row>
</template>

<script>
export default {
  name: 'FooterView',
  data () {
    return {
      pitch_on: '/MobileHome',
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        let path = to.path.substring(0, to.path.lastIndexOf("/"))
        let path2 = path.substring(0, path.lastIndexOf('/'))
        if (path == '' || path != this.pitch_on) {
          this.pitch_on = path == '' ? to.path : path2 == '' ? path : path2
          window.scrollTo(0, 0)
        }
      }
    }
  },
  computed: {
    routes () {
      let { routes } = this.$router.options
      return routes[1].children
    }
  },
}
</script>

<style lang="scss" scoped>
.Footer-item-col {
  text-align: center;
  padding: 7pt;
  .el-radio.is-bordered.is-checked {
    border-color: transparent !important;
  }
  ::v-deep {
    .Footer-item-col-div {
      padding: 0;
      &.is-bordered {
        border: none;
      }
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding: 0;
        > .el-image {
          margin-bottom: 2pt;
        }
        > div:nth-child(2) {
          color: #0e0e0e;
          font-size: 9pt;
        }
      }
    }
  }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import PClayout from '../layout/Pc-Index'
import Mobilelayout from '../layout/Mobile-Index'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '',
        component: PClayout,
        meta: {
            title: '首页',
            keepAlive: true, // 需要被缓存
        },
        navigate: true,
        children: [
            {
                path: '/',
                name: 'home',
                meta: {
                    title: '首页',
                    keepAlive: true, // 需要被缓存
                },
                navigate: true,
                component: () =>
                    import(/* webpackChunkName: "PC" */ '../views/PC-Views/home'),
            },
            {
                path: '/UserCase',
                // name: 'UserCase',
                meta: {
                    title: '用户案例',
                    keepAlive: true, // 需要被缓存
                },
                navigate: true,
                // 使用3级路由必须*
                component: { render (c) { return c('router-view') } },
                children: [
                    {
                        path: '',
                        // name: 'UserCase',
                        meta: {
                            title: '用户案例',
                            keepAlive: true, // 需要被缓存
                        },
                        navigate: true,
                        component: () =>
                            import(/* webpackChunkName: "PC" */ '../views/PC-Views/user-case'),
                    },
                    {
                        path: '/UserCase/CaseDetails/:id',
                        name: 'CaseDetails',
                        meta: {
                            title: '案例详情'
                        },
                        navigate: false,
                        component: () =>
                            import(/* webpackChunkName: "PC" */ '../views/PC-Views/user-case/case-details'),
                    },
                ]
            },
            {
                path: '/CorporateCulture',
                name: 'CorporateCulture',
                meta: {
                    title: '企业文化',
                    keepAlive: true, // 需要被缓存
                },
                navigate: true,
                component: () =>
                    import(/* webpackChunkName: "PC" */ '../views/PC-Views/corporate-culture'),
            },
            {
                path: '/Join_Us',
                name: 'Join_Us',
                meta: {
                    title: '加入我们',
                    keepAlive: true, // 需要被缓存
                },
                navigate: true,
                component: () =>
                    import(/* webpackChunkName: "PC" */ '../views/PC-Views/Join-us'),
            },
            {
                path: '/enterprise_trends',
                // name: 'enterprise_trends',
                meta: {
                    title: '企业动向',
                    keepAlive: true, // 需要被缓存
                },
                navigate: false,
                // 使用3级路由必须*
                component: { render (c) { return c('router-view') } },
                children: [
                    {
                        path: '',
                        name: 'enterprise_trends',
                        meta: {
                            title: '企业动向',
                            keepAlive: true, // 需要被缓存
                        },
                        component: () =>
                            import(/* webpackChunkName: "PC" */ '../views/PC-Views/enterprise-trends'),
                    },
                    {
                        path: '/enterprise_trends/trend-details/:id',
                        name: 'trend-details',
                        meta: {
                            title: '企业动向',
                            keepAlive: true, // 需要被缓存
                        },
                        component: () =>
                            import(/* webpackChunkName: "PC" */ '../views/PC-Views/enterprise-trends/trend-details'),
                    }
                ]

            },
        ]
    },
    {
        path: '/MobileHome',
        component: Mobilelayout,
        meta: {
            title: '首页',
            keepAlive: true
        },
        navigate: true,
        children: [
            {
                path: '/MobileHome',
                name: 'MobileHome',
                meta: {
                    title: '首页',
                    keepAlive: true,
                    icon: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/home.png',
                    icon2: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/home_select.png',
                },
                navigate: true,
                component: () =>
                    import(/* webpackChunkName: "Mobile" */ '../views/Mobile-Views/MobileHome'),
            },
            {
                path: '/MobileCase',
                meta: {
                    title: '案例',
                    keepAlive: true,
                    icon: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/case.png',
                    icon2: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/case_select.png',
                },
                navigate: true,
                // 使用3级路由必须*
                component: { render (c) { return c('router-view') } },
                children: [
                    {
                        path: '',
                        name: 'MobileCase',
                        meta: {
                            title: '案例',
                            keepAlive: true,
                            icon: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/case.png',
                            icon2: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/case_select.png',
                        },
                        component: () =>
                            import(/* webpackChunkName: "Mobile" */ '../views/Mobile-Views/MobileCase'),
                    },
                    {
                        path: '/MobileCase/MobileCase-details/:id',
                        meta: {
                            title: '案例详情',
                            keepAlive: true,
                            icon: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/case.png',
                            icon2: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/case_select.png',
                        },
                        component: () =>
                            import(/* webpackChunkName: "Mobile" */ '../views/Mobile-Views/MobileCase/MobileCase-details'),
                    }
                ]
            },
            {
                path: '/MobileHuamei',
                name: 'MobileHuamei',
                meta: {
                    title: '华美',
                    keepAlive: true,
                    icon: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/Huamei.png',
                    icon2: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/Huamei_select.png',
                },
                navigate: true,
                component: () =>
                    import(/* webpackChunkName: "Mobile" */ '../views/Mobile-Views/MobileHuamei'),
            },
            {
                path: '/MobileRecruit',
                name: 'MobileRecruit',
                meta: {
                    title: '招聘',
                    keepAlive: true,
                    icon: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/recruit.png',
                    icon2: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/icon/Mobile/recruit_select.png',
                },
                navigate: true,
                component: () =>
                    import(/* webpackChunkName: "Mobile" */ '../views/Mobile-Views/MobileRecruit'),
            },
            {
                path: '/MobileTrends',
                meta: {
                    title: '企业动向',
                    keepAlive: true,
                },
                navigate: false,
                // 使用3级路由必须*
                component: { render (c) { return c('router-view') } },
                children: [
                    {
                        path: '',
                        name: 'MobileTrends',
                        meta: {
                            title: '企业动向',
                            keepAlive: true,
                        },
                        component: () =>
                            import(/* webpackChunkName: "Mobile" */ '../views/Mobile-Views/MobileTrends'),
                    },
                    {
                        path: '/MobileTrends/trend-details/:id',
                        meta: {
                            title: '企业动向详情',
                            keepAlive: true,
                        },
                        component: () =>
                            import(/* webpackChunkName: "Mobile" */ '../views/Mobile-Views/MobileTrends/trend-details'),
                    }
                ]
            },
        ]
    }
]

const router = new VueRouter({
    // mode: 'hash',
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, saveTop) { if (saveTop) { return saveTop; } else { return { x: 0, y: 0 } } }
})

export default router
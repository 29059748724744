import { render, staticRenderFns } from "./Pc-Index.vue?vue&type=template&id=bb0b4082&scoped=true&"
import script from "./Pc-Index.vue?vue&type=script&lang=js&"
export * from "./Pc-Index.vue?vue&type=script&lang=js&"
import style0 from "./Pc-Index.vue?vue&type=style&index=0&id=bb0b4082&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb0b4082",
  null
  
)

export default component.exports
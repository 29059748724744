<template>
  <div class="Navbar">
    <div class="Navbar-Logo">
      <router-link to="/home"
                   custom
                   v-slot="{ navigate }">
        <el-image @click="navigate"
                  @keypress.enter="navigate"
                  style="width: 145px; height: 36px;"
                  :src="hmhtlogo"
                  fit="cover"></el-image>
      </router-link>
    </div>
    <div class="Navbar-Item">
      <template v-for="(item,index) in routes">
        <el-radio :key="index"
                  v-if="item.navigate"
                  v-on:click.native.prevent="$route.path != item.path?$router.push(item.path):''"
                  v-model="pitch_on"
                  :label="item.path"
                  border>{{item.meta.title}}</el-radio>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavbarView',
  data () {
    return {
      pitch_on: '/',
      hmhtlogo: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/hmhtlogo.png'
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        let path = to.path.substring(0, to.path.lastIndexOf("/"))
        let path2 = path.substring(0, path.lastIndexOf('/'))
        if (path == '' || path != this.pitch_on) {
          this.pitch_on = path == '' ? to.path : path2 == '' ? path : path2
          //   window.scrollTo(0, 0)
        }
      }
    }
  },
  computed: {
    routes () {
      let { routes } = this.$router.options
      return routes[0].children
    }
  },
  created () {

  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.Navbar {
  display: flex;
  margin: 0 auto;
  align-items: center;
  width: 1200px;
  height: 70px;
  .Navbar-Item {
    display: flex;
    align-items: center;
    margin-left: auto;
    ::v-deep {
      .el-radio {
        margin-right: 0;
        color: #666666;
        &.is-bordered {
          text-align: center;
          padding: 5px 0;
          border: none;
          width: 56px;
          height: 28px;

          &.is-checked,
          &:hover {
            border-bottom: 2px solid #ff6d00;
            border-radius: 0;
          }
          & + .is-bordered {
            margin-left: 110px;
          }
        }
        .el-radio__label {
          padding: 0;
          color: #666666;
        }
        .el-radio__inner {
          display: none;
        }
      }
    }
  }
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import waterfall from 'vue-waterfall2'

import 'element-ui/lib/theme-chalk/index.css'
import '@/views/page.scss'
import './permission.js'

Vue.config.productionTip = false

Vue.use(Element).use(waterfall)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')

<template>
  <div class="Navbar">
    <router-link to="/home"
                 custom
                 v-slot="{ navigate }">
      <el-image @click="navigate"
                @keypress.enter="navigate"
                style="width: 114pt; height: 29pt;"
                :src="hmhtlogo"
                fit="cover"></el-image>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data () {
    return {
      hmhtlogo: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/hmhtlogo.png'
    }
  }
}
</script>

<style lang="scss" scoped>
.Navbar {
  width: 100%;
  padding: 10.5pt 15pt;
}
</style>
<template>
  <div id="tomain">
    <Navbar class="Bavbar-item" />
    <app-main class="Main-item" />
    <Footer class="Footer-item" />
    <!-- 返回顶部按钮 -->
    <BackTop class="BackTop-item" />
  </div>
</template>

<script>
import { AppMain, Navbar, Footer, BackTop } from './components/Mobile-Index'
export default {
  name: 'LayoutView',
  components: { AppMain, Navbar, Footer, BackTop },
}
</script>

<style lang="scss" scoped>
#tomain {
  position: relative;
  overflow: hidden;
  .Bavbar-item {
    overflow: hidden;
    height: 2.5rem;
  }
  .Main-item {
    overflow: hidden;
    padding-bottom: 6rem;
  }
  .Footer-item {
    position: fixed;
    overflow: hidden;
    bottom: 0;
    width: 100%;
    height: 4rem;
    margin: 0 !important;
    z-index: 99999 !important;
    background: #ffffff;
    box-shadow: 0 -0.3rem 1rem 0.1rem rgba(253, 110, 5, 0.18);
  }
  .BackTop-item {
    ::v-deep {
      .toTop {
        bottom: 15% !important;
      }
    }
  }
}
</style>
import router from './router'
import NProgress from 'nprogress'


let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
var goUrl = 1

if (flag) {
    // router.push('/MobileHome')
    // location = '/MobileHome'
    goUrl = 0
} else {
    // router.push({ name: '/home' }).catch(err => { console.log(err) })
    goUrl = 1
}

NProgress.configure({
    showSpinner: false
})

const PcwhiteList = ['/'] // pc白名单
const whiteList = ['/MobileHome',] // 手机端白名单

// 路由钩子
router.beforeEach((to, from, next) => {
    // console.log(to, from)
    NProgress.start()
    if (to.meta.title) {
        document.title = to.meta.title + ' - 华美互通'
    }

    if (goUrl == 0) {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            if (to.path.indexOf('Mobile') === -1) {
                next('/MobileHome')
                NProgress.done()
            }
        }
    } else {
        if (PcwhiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            if (to.path.indexOf('Mobile') !== -1) {
                next('/')
                NProgress.done()
            }
        }
    }

    if (to.matched.length == 0) {
        if (window.history.length <= 1) {
            next('/')
            NProgress.done()
        } else {
            router.back(-1)
            NProgress.done()
        }
    }
    next()
})

router.afterEach(() => {
    NProgress.done()
})
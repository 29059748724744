<template>
  <div class="Footer"
       :style="`background:url(${page.bottomimg}) no-repeat;`">
    <div>
      <p>{{page.corporate_name}}</p>
      <p>地址：{{page.corporate_address}}</p>
      <p>电话：{{page.contact_information}}</p>
      <el-image style="width: 63px; height: 63px"
                :src="page.QR_code"
                fit="cover"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterView',
  data () {
    return {
      page: {
        bottomimg: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/bg-bottom.png',
        corporate_name: '北京华美互通广告有限公司 ',
        corporate_address: '北京市大兴亦庄经济技术开发区荣华南路1号国锐·金嵿广场A座7层',
        contact_information: '15600138075（同微信）',
        QR_code: 'https://weixinquncdn.oss-cn-beijing.aliyuncs.com/newhuamei/img/images/QR_code.png',
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Footer {
  text-align: center;
  background-size: 100% 100% !important;
  height: 250px;
  > div {
    color: #ffffff;
    padding: 20px;
    font-size: 16px;
    p:nth-child(1) {
      font-size: 38px;
    }
    p:nth-child(2) {
      margin-top: 20px;
    }
    P:nth-child(3) {
      margin-top: 12px;
      margin-bottom: 18px;
    }
  }
}
</style>
<template>
  <div id="tomain">
    <Navbar class="Bavbar-item" />
    <app-main class="Main-item" />
    <Footer class="Footer-item" />
    <!-- 返回顶部按钮 -->
    <BackTop />
  </div>
</template>

<script>
import { AppMain, Navbar, Footer, BackTop } from './components/Pc-Index'
export default {
  name: 'LayoutView',
  components: { AppMain, Navbar, Footer, BackTop },
}
</script>

<style lang="scss" scoped>
#tomain {
  min-width: 1200px;
}
.Main-item {
  padding-bottom: 60px;
}
</style>